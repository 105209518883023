<template>
    <Doughnut 
        :chart-options="chartOptions" 
        :chart-data="chartData" 
        :chart-id="chartId" 
        :dataset-id-key="datasetIdKey"
        :plugins="plugins" 
        :css-classes="cssClasses" 
        :styles="styles" 
        :width="width" 
        :height="height" />
</template>

<script>
import { Doughnut } from 'vue-chartjs/legacy'

import {
    Chart as ChartJS,
    Title,
    Tooltip,
    Legend,
    ArcElement,
    CategoryScale
} from 'chart.js'

ChartJS.register(Title, Tooltip, Legend, ArcElement, CategoryScale)

export default {
    name: 'DoughnutChart',
    components: {
        Doughnut
    },
    props: {
        chartId: {
            type: String,
            default: 'doughnut-chart'
        },
        datasetIdKey: {
            type: String,
            default: 'label'
        },
        width: {
            type: Number,
            default: 400
        },
        height: {
            type: Number,
            default: 400
        },
        cssClasses: {
            type: String,
            default: ''
        },
        styles: {
            type: Object,
            default: () => ({})
        },
        plugins: {
            type: Object,
            default: () => ({})
        },
        chartData: {
            type: Object,
            required: true
        },
        chartOptions: {
            type: Object,
            default: () => ({
                responsive: true,
                maintainAspectRatio: false
            })
        }
    }
}
</script>
