<template> 
  <v-container fluid>
    <v-toolbar rounded class="mb-4">
      <v-toolbar-title>Statistiques</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn color="primary" small @click="generateMail"
        :disabled="!dataSetByType.length && !dataSetByImpact.length && !dataSetByModule.length">
        Génerer un mail
      </v-btn>
    </v-toolbar>
    <v-row>
      <v-col cols="12" sm="4">
        <v-card theme="dark" class="rounded-xl d-flex flex-column justify-space-between" style="min-height: 180px;">
          <v-form @submit.prevent="fetchData">
            <v-row class="pa-2 pb-0">
              <v-col cols="12" md="6">
                <v-text-field dense v-model="formattedStartDate" label="Date de début" :rules="[dateRules]" outlined
                  readonly @click:append="startDatePicker = true" append-icon="mdi-calendar" hide-details />
                <v-dialog v-model="startDatePicker" persistent max-width="290px">
                  <v-date-picker v-model="startDate" @input="startDatePicker = false" />
                </v-dialog>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field dense v-model="formattedEndDate" label="Date de fin" :rules="[dateRules]" outlined readonly
                  @click:append="endDatePicker = true" append-icon="mdi-calendar" hide-details />
                <v-dialog v-model="endDatePicker" persistent max-width="290px">
                  <v-date-picker v-model="endDate" @input="endDatePicker = false" />
                </v-dialog>
              </v-col>
            </v-row>
            <v-row class="d-flex justify-center flex-wrap" style="gap: 1rem;">
              <v-checkbox v-model="selectedCategories" dense class="ma-0" hide-details value="type" label="Afficher par Type" />
              <v-checkbox v-model="selectedCategories" dense class="ma-0" hide-details value="impact" label="Afficher par Impact" />
              <v-checkbox v-model="selectedCategories" dense class="ma-0" hide-details value="module" label="Afficher par Module" />
              <v-checkbox v-model="selectedCategories" dense class="ma-0" hide-details value="client" label="Afficher par Client" />
              <v-checkbox v-model="selectedCategories" dense class="ma-0" hide-details value="all" label="Afficher Tout"
                @change="selectedCategories = ['type', 'impact', 'module', 'client']" />
            </v-row>
            <!-- mettre le btn small si lg -->
            <v-col align="center" class="pb-0 pt-4" :class="$vuetify.breakpoint.name === 'lg' ? 'pb-1' : ''"> 
              <v-btn :small="$vuetify.breakpoint.name === 'lg'" 
               type="submit" color="success">Afficher les Statistiques</v-btn>
            </v-col>
          </v-form>
        </v-card>
      </v-col>
      <v-col cols="12" sm="2">
        <v-card   class="rounded-xl" style="min-height: 180px;">
          <div v-if="dataSetByType.length > 0">
            <v-simple-table class="elevation-2" color="white">
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left">Type</th>
                    <th class="text-right
                    ">Nombre</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Anomalies</td>
                    <td class="text-right">{{ dataSetByType[0] }}</td>
                  </tr>
                  <tr>
                    <td>Utilisations</td>
                    <td class="text-right">{{ dataSetByType[1] }}</td>
                  </tr>
                  <tr>
                    <td>Renseignements</td>
                    <td class="text-right">{{ dataSetByType[2] }}</td>
                  </tr>
                  <tr>
                    <td>Développements</td>
                    <td class="text-right">{{ dataSetByType[3] }}</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </div> 
        </v-card>
      </v-col>
      <v-col cols="12" sm="2">
        <v-card class="rounded-xl" style="min-height: 180px;">
          <div v-if="dataSetByImpact.length > 0">
            <v-simple-table class="elevation-2" color="white">
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left">Impact</th>
                    <th class="text-right">Nombre</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <v-chip color="#FF6384" text-color="white">Bloquant</v-chip>
                    </td>
                    <td class="text-right">
                      <v-chip color="#FF6384" text-color="white">{{ dataSetByType[0] }}</v-chip>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <v-chip color="#36A2EB" text-color="white">Majeur</v-chip>
                    </td>
                    <td class="text-right">
                      <v-chip color="#36A2EB" text-color="white">{{ dataSetByType[1] }}</v-chip>
                    </td>
                    
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </div>
        </v-card>
      </v-col>
      <v-col cols="12" sm="2">
        <v-card color="#2A9AF3" theme="dark" class="rounded-xl" style="min-height: 180px;">
          <div class="d-flex flex-no-wrap justify-space-between">
            <div>
              <v-card-title class="text-h5 mt-10"> Arbus 811 </v-card-title>
              <v-card-subtitle>$620</v-card-subtitle>
            </div>
            <v-img src="av.png" class="mr-4 mt-5" absolute></v-img>
          </div>
        </v-card>
      </v-col>
      <v-col cols="12" sm="2">
        <v-card color="#2A9AF3" theme="dark" class="rounded-xl" style="min-height: 180px;">
          <div class="d-flex flex-no-wrap justify-space-between">
            <div>
              <v-card-title class="text-h5 mt-10"> Total Flights </v-card-title>
              <v-card-subtitle>850</v-card-subtitle>
            </div>
            <v-img src="map.png" class="mr-4 mt-5" absolute></v-img>
          </div>
        </v-card>
      </v-col>
      <v-col cols="12" sm="3" class="pt-0">
        <div v-if="selectedCategories.includes('type')">
        <!-- toolbar -->
        <v-toolbar rounded flat dense color="primary" dark>
          <v-toolbar-title>Type</v-toolbar-title>
        </v-toolbar>
        <v-card class="mx-auto" style="min-height: 405px;">
          <DoughnutChart ref="doughnutChart" :chart-data="chartDataSetByImpact" :chart-options="doughnutChartOptions" />
        </v-card>
        </div>
      </v-col>
      <v-col cols="12" sm="3" class="pt-0">
        <div v-if="selectedCategories.includes('impact')">
        <!-- toolbar -->
        <v-toolbar rounded flat dense color="primary" dark>
          <v-toolbar-title>Impact</v-toolbar-title>
        </v-toolbar>
        <v-card class="mx-auto" style="min-height: 405px;">
          <BarChart ref="barChart" :chart-data="chartDataSetByType" :chart-options="BarChartOptions" />
        </v-card>
        </div>
      </v-col>
      <v-col cols="12" sm="3" class="pt-0">
        <div v-if="selectedCategories.includes('module')">
        <!-- toolbar -->
        <v-toolbar rounded flat dense color="primary" dark>
          <v-toolbar-title>Module</v-toolbar-title>
        </v-toolbar>
        <v-card class="mx-auto" style="min-height: 405px;">
          <PieChart ref="pieChartModule" :chart-data="chartDataSetByModule" :chart-options="pieChartOptions" />
        </v-card>
        </div>
      </v-col>
      <v-col cols="12" sm="3" class="pt-0">
        <div v-if="selectedCategories.includes('client')">
        <!-- toolbar -->
        <v-toolbar rounded flat dense color="primary" dark>
          <v-toolbar-title>Client</v-toolbar-title>
        </v-toolbar>
        <v-card class="mx-auto" style="min-height: 405px;">
          <PieChart ref="pieChartClient" :chart-data="chartDataSetByClient" :chart-options="pieChartOptions" />

        </v-card>
        </div>
      </v-col>
      <v-col cols="12" sm="3" class="pt-0">
        <v-card class="mx-auto">
          <template v-slot:subtitle> Overview of latest month </template>
          <v-card-text class="py-0 mt-4">
            <v-row align="center">
              <v-col cols="12">
                <!-- Content -->
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" sm="4" class="mt-4">
        <v-card style="min-height: 155px;">
          <PieChart :chart-data="chartDataSetByClient" :chart-options="pieChartOptions" />
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>


<script>
import BarChart from './Bar.vue'
import DoughnutChart from './Doughnut.vue';
import PieChart from './Pie.vue';
import MessagerieService from '../../../../Services/MessagerieService';
import moment from 'moment';
import 'moment/locale/fr'; // Importer la locale française

moment.locale('fr'); // Utiliser la locale française

export default {
  name: 'App',
  components: {
    BarChart,
    DoughnutChart,
    PieChart
  },
  data() {
    return {
      dataSetByType: [],
      startDateByType: null,
      endDateByType: null,
      startDatePickerByType: false,
      endDatePickerByType: false,
      dataSetByImpact: [],
      startDateByImpact: null,
      endDateByImpact: null,
      startDatePickerByImpact: false,
      endDatePickerByImpact: false,
      startDateByModule: null,
      endDateByModule: null,
      startDatePickerByModule: false,
      endDatePickerByModule: false,
      dataSetByModule: [],
      selectedCategories: [],
      startDate: '12/01/2023',
      endDate: '12/08/2024',
      startDatePicker: false,
      endDatePicker: false,
      loader: false,
      dataSetByModuleDetails: {},
      dataSetByClientDetails: {},
      dateRules: [
        v => !!v || 'La date est requise',
        v => (v && new Date(v) <= new Date()) || 'La date ne peut pas être dans le futur'
      ],
      BarChartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          x: {
            beginAtZero: true
          },
          y: {
            beginAtZero: true
          }
        }
      },
      doughnutChartOptions: {
        responsive: true,
        maintainAspectRatio: false,
      },
      pieChartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            display: true,
            position: 'right',
            labels: {
              boxWidth: 20,
              padding: 10
            }
          },
          tooltip: {
            callbacks: {
              label: function (context) {
                let label = context.label || '';
                if (label) {
                  label += ': ';
                }
                if (context.parsed !== null) {
                  label += `${context.parsed} (${context.raw}%)`; // Affiche les valeurs et pourcentages
                }
                return label;
              }
            }
          }
        }
      }
    };
  },
  computed: {
    formattedStartDate() {
      return this.startDate ? moment(this.startDate).format('DD/MM/YYYY') : ''
    },
    formattedEndDate() {
      return this.endDate ? moment(this.endDate).format('DD/MM/YYYY') : ''
    },
    chartDataSetByType() {
      return {
        labels: ['Anomalies', 'Utilisations', 'Renseignements', 'Développements'],
        datasets: [
          {
            label: 'Statistiques par Type',
            backgroundColor: ['#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0'],
            data: this.dataSetByType
          }
        ]
      };
    },
    chartDataSetByImpact() {
      return {
        labels: ['Bloquant', 'Majeur', 'Mineur', 'Sans Impact'],
        datasets: [
          {
            label: 'Statistiques par Impact',
            backgroundColor: ['#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0'],
            data: this.dataSetByImpact
          }
        ]
      };
    },
    chartDataSetByModule() {
      const labels = Object.keys(this.dataSetByModule); // Les noms des modules
      const data = Object.values(this.dataSetByModule); // Les occurrences des modules

      const backgroundColors = labels.map((_, index) => {
        // Génération des couleurs, peut être aléatoire ou défini
        const colors = ['#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0', '#9966FF', '#FF9F40'];
        return colors[index % colors.length];
      });

      return {
        labels: labels,  // Noms des modules
        datasets: [
          {
            label: 'Statistiques par Module',
            backgroundColor: backgroundColors,
            data: data // Données des modules
          }
        ]
      };
    },
    chartDataSetByClient() {
      const labels = Object.keys(this.dataSetByClientDetails); // Les noms des clients
      const data = Object.values(this.dataSetByClientDetails); // Les occurrences des clients

      const backgroundColors = labels.map((_, index) => {
        // Génération des couleurs, peut être aléatoire ou défini
        const colors = ['#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0', '#9966FF', '#FF9F40'];
        return colors[index % colors.length];
      });

      return {
        labels: labels,  // Noms des clients
        datasets: [
          {
            label: 'Statistiques par Client',
            backgroundColor: backgroundColors,
            data: data // Données des clients
          }
        ]
      };
    }
  },
  methods: {
    getRandomColor() {
      const letters = '0123456789ABCDEF';
      let color = '#';
      for (let i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];
      }
      return color;
    },
    async fetchData() {
      try {
        this.loader = true;
        if (this.startDate && this.endDate) {
          const formattedStartDate = moment(this.startDate).format('DD/MM/YYYY');
          const formattedEndDate = moment(this.endDate).format('DD/MM/YYYY');

          if (this.selectedCategories.includes('type') || this.selectedCategories.includes('all')) {
            const responseType = await MessagerieService.getFichesByType(formattedStartDate, formattedEndDate);
            const countsType = this.countTypeDemande(responseType.data);
            const dataType = this.formatCountsByType(countsType);
            this.dataSetByType = [
              dataType.anomalies,
              dataType.utilisations,
              dataType.renseignements,
              dataType.developpements
            ];
          }

          if (this.selectedCategories.includes('impact') || this.selectedCategories.includes('all')) {
            const responseImpact = await MessagerieService.getFichesByImpact(formattedStartDate, formattedEndDate);
            const countsImpact = this.countImpactDemande(responseImpact.data);
            const dataImpact = this.formatCountsByImpact(countsImpact);
            this.dataSetByImpact = [
              dataImpact.bloquant,
              dataImpact.majeur,
              dataImpact.mineur,
              dataImpact.sansImpact
            ];
          }

          if (this.selectedCategories.includes('module') || this.selectedCategories.includes('all')) {
            const responseModule = await MessagerieService.getFichesByModule(formattedStartDate, formattedEndDate);
            this.dataSetByModule = this.formatCountsByModule(responseModule.data);

            // Récupération des détails des modules
            this.dataSetByModuleDetails = responseModule.data.reduce((acc, item) => {
              const module = item.fiche.module.name;
              if (acc[module]) {
                acc[module]++;
              } else {
                acc[module] = 1;
              }
              return acc;
            }, {});

            // Récupération des détails des clients
            this.dataSetByClientDetails = responseModule.data.reduce((acc, item) => {
              const client = item.fiche.userCreate.url;
              if (acc[client]) {
                acc[client]++;
              } else {
                acc[client] = 1;
              }
              return acc;
            }, {});
          }

          if (this.selectedCategories.includes('client') || this.selectedCategories.includes('all')) {
            const responseClient = await MessagerieService.getFichesByClient(formattedStartDate, formattedEndDate);
            this.dataSetByClientDetails = responseClient.data.reduce((acc, item) => {
              const client = item.fiche.userCreate.url;
              if (acc[client]) {
                acc[client]++;
              } else {
                acc[client] = 1;
              }
              return acc;
            }, {});
          }

          this.loader = false;
        }
      } catch (error) {
        console.error("Erreur lors de la récupération des données:", error);
      }
    },
    countTypeDemande(data) {
      return data.reduce((acc, item) => {
        switch (item.fiche.type_demande.text) {
          case 'type_demande.anomalie': acc.anomalies++; break;
          case 'type_demande.utilisation': acc.utilisations++; break;
          case 'type_demande.renseignement': acc.renseignements++; break;
          case 'type_demande.developpement': acc.developpements++; break;
          default: break;
        }
        return acc;
      }, { anomalies: 0, utilisations: 0, renseignements: 0, developpements: 0 });
    },
    countImpactDemande(data) {
      return data.reduce((acc, item) => {
        switch (item.fiche.impact.text) {
          case 'impact.bloquant': acc.bloquant++; break;
          case 'impact.majeur': acc.majeur++; break;
          case 'impact.mineur': acc.mineur++; break;
          case 'impact.sansImpact': acc.sansImpact++; break;
          default: break;
        }
        return acc;
      }, { bloquant: 0, majeur: 0, mineur: 0, sansImpact: 0 });
    },
    formatCountsByType(counts) {
      return {
        anomalies: counts.anomalies,
        utilisations: counts.utilisations,
        renseignements: counts.renseignements,
        developpements: counts.developpements
      };
    },
    formatCountsByImpact(counts) {
      return {
        bloquant: counts.bloquant,
        majeur: counts.majeur,
        mineur: counts.mineur,
        sansImpact: counts.sansImpact
      };
    },
    formatCountsByModule(documents) {
      const counts = {};
      documents.forEach(doc => {
        const module = doc.fiche.module.name;
        if (counts[module]) {
          counts[module]++;
        } else {
          counts[module] = 1;
        }
      });
      return counts;
    },
    generateImage(chartType) {
      // const chartInstance = document.getElementById(chartType);
      const chartInstance = this.$refs[chartType].$el.firstChild;
      if (chartInstance) {
        return chartInstance.toDataURL('image/png');
      }
      return null;
    },
    async generateMail() {
      try {
    if (
      this.dataSetByType.length > 0 ||
      this.dataSetByImpact.length > 0 ||
      this.dataSetByModule.length > 0 
    ) {
      let emailContent = `
        <h2 style="color: #333;">Statistiques des Fiches de Liaison</h2>
        <p>Période : du ${this.formattedStartDate} au ${this.formattedEndDate}</p>
        <table style="width: 100%; border-collapse: collapse;">
          <tr>
            <th style="border: 1px solid #ddd; padding: 8px; background-color: #f2f2f2;">Par Type</th>
            <th style="border: 1px solid #ddd; padding: 8px; background-color: #f2f2f2;">Par Impact</th>
          </tr>
          <tr>
            <td style="border: 1px solid #ddd; padding: 8px;">
              <table style="width: 100%;">
                <tr>
                  <td>Anomalies</td>
                  <td style="text-align: right;">${this.dataSetByType[0]}</td>
                </tr>
                <tr>
                  <td>Utilisations</td>
                  <td style="text-align: right;">${this.dataSetByType[1]}</td>
                </tr>
                <tr>
                  <td>Renseignements</td>
                  <td style="text-align: right;">${this.dataSetByType[2]}</td>
                </tr>
                <tr>
                  <td>Développements</td>
                  <td style="text-align: right;">${this.dataSetByType[3]}</td>
                </tr>
              </table>
            </td>
            <td style="border: 1px solid #ddd; padding: 8px;">
              <table style="width: 100%;">
                <tr>
                  <td>Bloquant</td>
                  <td style="text-align: right;">${this.dataSetByImpact[0]}</td>
                </tr>
                <tr>
                  <td>Majeur</td>
                  <td style="text-align: right;">${this.dataSetByImpact[1]}</td>
                </tr>
                <tr>
                  <td>Mineur</td>
                  <td style="text-align: right;">${this.dataSetByImpact[2]}</td>
                </tr>
                <tr>
                  <td>Sans Impact</td>
                  <td style="text-align: right;">${this.dataSetByImpact[3]}</td>
                </tr>
              </table>
            </td>
          </tr>
        </table>
        
        <h3 style="color: #333; margin-top: 20px;">Répartition par Module</h3>
        <table style="width: 100%; border-collapse: collapse;">
          ${Object.entries(this.dataSetByModule)
            .map(([module, count]) => `
              <tr>
                <td style="border: 1px solid #ddd; padding: 8px;">${module}</td>
                <td style="border: 1px solid #ddd; padding: 8px; text-align: right;">${count}</td>
              </tr>
            `)
            .join('')}
        </table>
        
        <h3 style="color: #333; margin-top: 20px;">Détails par Client</h3>
        <table style="width: 100%; border-collapse: collapse;">
          ${Object.entries(this.dataSetByClientDetails)
            .map(([client, count]) => `
              <tr>
                <td style="border: 1px solid #ddd; padding: 8px;">${client}</td>
                <td style="border: 1px solid #ddd; padding: 8px; text-align: right;">${count}</td>
              </tr>
            `)
            .join('')}
        </table>
      `;

      // Attendre que les graphiques soient rendus
      await this.$nextTick();

      // Ajout des images des graphiques
      if (this.dataSetByType.length > 0) {
        emailContent += `
          <h3 style="color: #333; margin-top: 20px;">Graphique par Type</h3>
          <img src="${this.generateImage('barChart')}" alt="Bar Chart" style="max-width: 100%; height: auto;" />
        `;
      }

      if (this.dataSetByImpact.length > 0) {
        emailContent += `
          <h3 style="color: #333; margin-top: 20px;">Graphique par Impact</h3>
          <img src="${this.generateImage('doughnutChart')}" alt="Doughnut Chart" style="max-width: 100%; height: auto;" />
        `;
      }

      if (Object.keys(this.dataSetByModule).length > 0) {
        emailContent += `
          <h3 style="color: #333; margin-top: 20px;">Graphique par Module</h3>
          <img src="${this.generateImage('pieChartModule')}" alt="Pie Chart" style="max-width: 100%; height: auto;" />
        `;
      }

      if (Object.keys(this.dataSetByClientDetails).length > 0) {
        emailContent += `
          <h3 style="color: #333; margin-top: 20px;">Graphique par Client</h3>
          <img src="${this.generateImage('pieChartClient')}" alt="Pie Chart" style="max-width: 100%; height: auto;" />
        `;
      }

      // Envoyer l'email via le service MessagerieService
      await MessagerieService.sendMail({
        mailSubject: `Statistiques des Fiches de Liaison du ${this.formattedStartDate} au ${this.formattedEndDate}`,
        mailContent: emailContent,
        mailTo: 'mkhenissi@planete-online.fr',
      });

      this.$nSuccess('L\'e-mail a été généré et envoyé avec succès.');
    } else {
      this.$nError('Aucune donnée disponible pour générer un e-mail.');
    }
  } catch (error) {
    console.error("Erreur lors de la génération de l'e-mail:", error);
    this.$nError('Une erreur est survenue lors de la génération de l\'e-mail.');
  }
    }
  },
  mounted() {
  }
};
</script>

<style scoped></style>
